<template>
  <div>
    <div class="row">
      <div class="col-12">
        <q-list bordered class="rounded-borders">
          <q-expansion-item 
            dense dark default-opened
            icon="account_tree" 
            header-class="bg-blue-grey-6 text-white"
            expand-icon-class="text-white"
            :label="$language('공정 정보')" >
            <q-separator />
            <q-card class="q-pa-sm">
              <div class="row">
                <div class="col-xs-12 col-sm-3 col-md-2">
                  <c-label-text title="평가명" :value="assessPlan.assessmentName"></c-label-text>
                </div>
                <div class="col-xs-12 col-sm-3 col-md-2">
                  <c-label-text title="평가기법" :value="assessPlan.ramTechniqueName"></c-label-text>
                </div>
                <div class="col-xs-12 col-sm-3 col-md-2">
                  <c-label-text title="평가대상공정" :value="process.processName"></c-label-text>
                </div>
                <div class="col-xs-12 col-sm-3 col-md-2">
                  <c-label-text title="작업발생주기" :value="process.workCycleName"></c-label-text>
                </div>
                <div class="col-xs-12 col-sm-3 col-md-2">
                  <c-label-text title="작업시간(1회)" :value="process.workingTime"></c-label-text>
                </div>
              </div>
            </q-card>
          </q-expansion-item>
        </q-list>
      </div>
    </div>
    <div class="row">
      <div :class="[scenarioViewGroup==='L' ? 'col-12' : 'col-xs-12 col-sm-6 col-md-4 col-lg-4', 'scenario-list']"> 
        <!-- :hideHeader="scenarioViewGroup==='C'" -->
        <c-table
          ref="table"
          title="시나리오 목록"
          :gridHeight="setheight"
          :columns="gridColumns"
          :data="process.fmScenarios"
          :merge="scenarioViewGroup==='L' ? grid.merge : []"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&!disabled"
          :isExcelDown="scenarioViewGroup==='L'"
          :isFullScreen="scenarioViewGroup==='L'"
          :customTrClass="setTrClass"
          selection="multiple"
          rowKey="ram4mAssessScenarioId"
          @callbackProxy="callbackProxy"
          @table-data-change="tableDataChange"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <!-- 범례 -->
            <q-chip 
              outline square clickable 
              size="md"
              color="red" 
              text-color="white"
              icon="help"
              :label="$language('범례')"
              style="height:26px;margin-right:2px !important;"
              v-show="editable&&!disabled&&scenarioViewGroup==='L'"
            >
              <q-popup-proxy>
                <q-banner>
                  <q-btn icon="add" label="" color="red" size="7px"></q-btn>
                  : {{$language('개선요청 등록')}}
                </q-banner>
              </q-popup-proxy>
            </q-chip>
            <q-btn-group outline >
              <c-update-btn 
                name="updateBtn"
                :data="updateBtnData"
                :btnEditable="btnEditable"
                :flagCondition="flagCondition"
                @back="back"
              />
              <c-btn 
                v-show="isCompared" 
                label="이전평가결과조회" 
                icon="compare_arrows" 
                @btnClicked="compareScenario" />
              <c-btn 
                v-show="editable&&!disabled&&!btnEditable" 
                :isSubmit="isComplete"
                :url="completeUrl"
                :param="process"
                mappingType="PUT"
                label="평가완료" 
                icon="check"
                @beforeAction="completeProcess"
                @btnCallback="completeProcessCallback" />
              <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" @btnClicked="removeScenario" />
              <c-btn 
                v-show="editable&&!disabled&&scenarioViewGroup==='L'" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="process.fmScenarios"
                mappingType="PUT"
                label="저장" 
                icon="save"
                @beforeAction="saveScenario"
                @btnCallback="saveScenarioCallback" />
            </q-btn-group>
          </template>
          <template v-slot:suffixTitle>
            <q-btn-group outline class="scenario-view-group">
              <q-btn 
                :outline="scenarioViewGroup!=='L'"
                :color="scenarioViewGroup==='L' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="view_list"
                @click="listView"
              >
                <q-tooltip>
                  {{$language('목록형')}}
                </q-tooltip>
              </q-btn>
              <q-btn 
                :outline="scenarioViewGroup!=='C'"
                :color="scenarioViewGroup==='C' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="space_dashboard"
                @click="cardView"
              >
                <q-tooltip>
                  {{$language('카드형')}}
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="editable && Boolean(props.row.ram4mAssessScenarioId)"
                :requestContentsCols="requestContentsCols"
                tableKey="ram4mAssessScenarioId"
                ibmTaskTypeCd="ITT0000023"
                ibmTaskUnderTypeCd="ITTU000030"
                @imprChange="imprChange"
              />
            </template>
            <template v-else-if="col.name==='item'">
              <q-item class="card-scenario-list">
                <q-item-section>
                  <q-item-label class="scenario-card-title">{{`${props.row['jobName']} / ${props.row['ram4mRiskHazardClassName']} / ${props.row['riskHazardName']}`}}</q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-icon name="arrow_forward_ios" class="text-primary"
                  style="font-size: 20px;"
                  @click="getDetail(props.row)"
                  ></q-icon>
                </q-item-section>
              </q-item>
            </template>
            <template v-else-if="col.name==='riskHazardPicture'">
              <q-icon
                style="font-size: 30px; cursor:pointer;"
                class="text-success" 
                name="image"
                @click.stop="openHazardPicture(props)" /> 
              <!-- <c-upload-picture
                class="scenario4m"
                height="35px"
                :attachInfo="{
                  isSubmit: '',
                  taskClassCd: 'RISK_HAZARD',
                  taskKey: props.row.ramRiskHazardId,
                }"
                :editable="editable&&!disabled&&selectScenario">
              </c-upload-picture> -->
            </template>
            <template v-else-if="col.name==='picture'">
              <q-icon
                style="font-size: 30px; cursor:pointer;"
                class="text-primary" 
                name="image"
                @click.stop="openImprPicture(props)" />  
            </template>
            <template v-else-if="col.name==='addingRiskManagementActivities'">
              <c-textarea-column
                :editable="editable"
                :disabled="disabled"
                :props="props"
                :col="col"
                v-model="props.row['addingRiskManagementActivities']"
              >
              </c-textarea-column>
            </template>
          </template>
        </c-table>
      </div>
      <div v-if="scenarioViewGroup==='C'" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card">
        <q-form ref="editForm">
          <div class="row">
            <div class="col-5 pb-3">
              <c-card title="상세정보" class="cardClassDetailForm no-margin">
                <template slot="card-button">
                  <q-btn-group outline >
                    <c-btn 
                      v-show="editable && !disabled" 
                      :isSubmit="isSaveUnit"
                      :url="saveUnitUrl"
                      :param="scenario"
                      mappingType="PUT"
                      label="저장" 
                      icon="save"
                      @beforeAction="saveScenarioUnit"
                      @btnCallback="saveScenarioUnitCallback" />
                  </q-btn-group>
                </template>
                <template slot="card-detail">
                  <div class="col-12">
                    <c-upload-picture
                      class="scenario4m2"
                      height="250px"
                      :attachInfo="attachHazardInfo"
                      :editable="editable&&!disabled&&selectScenario">
                    </c-upload-picture>
                  </div>
                  <div class="col-12">
                    <c-text
                      :disabled="true"
                      :editable="editable"
                      label="작업 / 평가구분 / 유해위험요인"
                      name="scenarioTarget"
                      v-model="scenarioTarget">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <c-text
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="피해대상"
                      name="damageTargetName"
                      v-model="scenario.damageTargetName">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <c-text
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="재해형태"
                      name="damageTypeName"
                      v-model="scenario.damageTypeName">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <c-select
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      type="edit"
                      codeGroupCd="RAM_OCCURR_CLASS_CD"
                      itemText="codeName"
                      itemValue="code"
                      label="발생형태"
                      name="ramOccurrClassCd"
                      v-model="scenario.ramOccurrClassCd"
                    ></c-select>
                  </div> 
                  <div class="col-6">
                    <c-checkbox
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :isFlag="true"
                      label="위험등록부"
                      name="riskbookFlag"
                      v-model="scenario.riskbookFlag"
                    />
                  </div>
                  <div class="col-6">
                    <c-datepicker
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="평가일"
                      name="assessDate"
                      v-model="scenario.assessDate"
                    />
                  </div>
                  <div class="col-6">
                    <c-field
                      v-if="!param.vendorFlag"
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :data="scenario"
                      label="평가자"
                      name="assessUserId"
                      v-model="scenario.assessUserId"
                      @username="(val) => { scenario.assessUserName = val }" />
                    <c-text
                      v-else
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="평가자"
                      name="assessUserName"
                      v-model="scenario.assessUserName">
                    </c-text>
                  </div>
                </template>
              </c-card>
            </div>
            <div class="col-7">
              <c-card title="개선정보" class="cardClassDetailForm no-margin">
                <template slot="card-detail">
                  <div class="col-4">
                    <c-upload-picture
                      :isMultiTask="scenario.imprs && scenario.imprs.length > 0"
                      :attachInfo="attachBeforeInfo"
                      :editable="editable&&!disabled&&selectScenario"
                    >
                    </c-upload-picture>
                  </div>
                  <div class="col-5">
                    <c-textarea
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :rows="3"
                      label="현재안전조치"
                      name="existingRiskManagementActivities"
                      v-model="scenario.existingRiskManagementActivities">
                    </c-textarea>
                  </div>
                  <div class="col-3 risk">
                    <q-list dense bordered separator>
                      <q-item>
                        <div class="card-risk-info detail-risk">
                          <q-item-section>
                            <q-item-label>{{$language('빈도')}}</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-item-label>{{scenario.beforeFrequency}}</q-item-label>
                          </q-item-section>
                        </div>
                        <q-separator spaced vertical />
                        <div class="card-risk-info detail-risk">
                          <q-item-section>
                            <q-item-label>{{$language('강도')}}</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-item-label>{{scenario.beforeStrength}}</q-item-label>
                          </q-item-section>
                        </div>
                      </q-item>
                      <!-- <q-separator spaced inset /> -->
                      <q-item class="detail-risk">
                        <q-item-section>
                          <q-item-label>{{$language('위험도')}}</q-item-label>
                        </q-item-section>

                        <q-item-section side class="detail-risk-num">
                          <q-item-label>{{scenario.beforeRisk}}</q-item-label>
                        </q-item-section>
                        <q-item-section v-if="!disabled&&selectScenario" side class="detail-risk-num">
                          <q-btn 
                            unelevated round dense
                            size="10px"
                            color="primary" 
                            icon="create">
                            <q-popup-proxy ref="beforeRiskProxy">
                              <component
                                :is="riskPopComponent"
                                width="800px"
                                :props="{
                                  row: {
                                    ramMatrixId: scenario.ramMatrixId
                                  }
                                }"
                                :editable="editable&&!disabled&&selectScenario"
                                @callback="(data, color) => callbackCardProxy(data, color, 'beforeRiskProxy')"
                              />
                            </q-popup-proxy>
                          </q-btn>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </div>
                  <div class="col-12 improveFlag">
                    <c-radio
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :comboItems="improveFlagItems"
                      label="개선진행여부"
                      name="improveFlag"
                      v-model="scenario.improveFlag"
                      @datachange="improveFlagChange">
                    </c-radio>
                  </div> 
                  <div class="col-12" style="margin-top:-30px !important">
                    <c-table
                      title="개선 목록"
                      :editable="editable&&!disabled&&selectScenario"
                      :columns="gridImpr.columns"
                      :data="scenario.imprs"
                      :gridHeight="gridImpr.height"
                      :usePaging="false"
                      :columnSetting="false"
                      :isFullScreen="false"
                      :filtering="false"
                      :isExcelDown="false"
                      @linkClick="(row) => linkClick({ sopImprovementId: row.sopImprovementId, ram4mAssessImprId: row.ram4mAssessImprId }, row)"
                    >
                      <template slot="table-button">
                        <q-btn-group outline>
                          <c-btn 
                            v-if="editable&&!disabled&&selectScenario" 
                            label="개선요청" 
                            icon="add" 
                            @btnClicked="openImprRequest" />
                        </q-btn-group>
                      </template>
                    </c-table>
                  </div>
                  <div class="col-4">
                    <c-upload-picture
                      :isMultiTask="scenario.imprs && scenario.imprs.length > 0"
                      :attachInfo="attachAfterInfo"
                      :editable="editable&&!disabled&&selectScenario&&afterRiskEditable">
                    </c-upload-picture>
                  </div>
                  <div class="col-5">
                    <c-textarea
                      :disabled="disabled||!selectScenario||!afterRiskEditable"
                      :editable="editable"
                      :rows="3"
                      label="추가 리스크관리 계획"
                      name="addingRiskManagementActivities"
                      v-model="scenario.addingRiskManagementActivities">
                    </c-textarea>
                  </div>
                  <div class="col-3 risk">
                    <q-list dense bordered separator>
                      <q-item>
                        <div class="card-risk-info detail-risk">
                          <q-item-section>
                            <q-item-label>{{$language('빈도')}}</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-item-label>{{scenario.afterFrequency}}</q-item-label>
                          </q-item-section>
                        </div>
                        <q-separator spaced vertical />
                        <div class="card-risk-info detail-risk">
                          <q-item-section>
                            <q-item-label>{{$language('강도')}}</q-item-label>
                          </q-item-section>

                          <q-item-section side>
                            <q-item-label>{{scenario.afterStrength}}</q-item-label>
                          </q-item-section>
                        </div>
                      </q-item>
                      <!-- <q-separator spaced inset /> -->
                      <q-item class="detail-risk">
                        <q-item-section>
                          <q-item-label>{{$language('위험도')}}</q-item-label>
                        </q-item-section>

                        <q-item-section side class="detail-risk-num">
                          <q-item-label>{{scenario.afterRisk}}</q-item-label>
                        </q-item-section>
                        <q-item-section v-if="!disabled&&selectScenario&&afterRiskEditable" side class="detail-risk-num">
                          <q-btn 
                            unelevated round dense
                            size="10px"
                            color="primary" 
                            icon="create">
                            <q-popup-proxy ref="afterRiskProxy">
                              <component
                                :is="riskPopComponent"
                                width="800px"
                                :props="{
                                  row: {
                                    ramMatrixId: scenario.ramMatrixId
                                  }
                                }"
                                :editable="editable&&!disabled&&selectScenario"
                                @callback="(data, color) => callbackCardProxy(data, color, 'afterRiskProxy')"
                              />
                            </q-popup-proxy>
                          </q-btn>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </div>
                </template>
              </c-card>
            </div>
          </div>
        </q-form>
      </div>
    </div>
    <c-win-pop 
      ref="winPopup"  
      @onClose="val=>evtCloseWinPopup(val)"  
      @onRecvEvtFromWinPop="val=>onRecvWinPop(val)" />
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'fm-scenario',

  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        assessUserName: '',  // 평가 담당자
        workCycleCd: null,  // 작업발생주기코드
        workCycleName: '',  // 작업발생주기
        workingTime: 0,  // 작업시간(1회)
        ramMatrixId: '',
        fmScenarios: [],
        reCheckedCnt: 0,
      }),
    },
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    planUpdateBtnData: {
      title: '계획', // 계획
      flag: false,
      research: '',
      planResearch: '',
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'jobName' },
          { index: 1, colName: 'ram4mRiskHazardClassGroup' },
        ],
        data: [],
      },
      gridImpr: {
        columns: [
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            // style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행단계',
            align: 'center',
            // style: 'width:85px',
            sortable: true
          },
          {
            name: 'improveRequestDeptName',
            field: 'improveRequestDeptName',
            label: '요청부서',
            align: 'center',
            // style: 'width:85px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            // style: 'width:85px',
            sortable: true,
          },
        ],
        height: '260px'
      },
      scenario: {
        ram4mAssessScenarioId: '',  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정
        mdmSopId: '',  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
        jobName: '',  // 안전작업 명
        ramRiskHazardId: '',  // 유해위험요인 번호
        riskHazardName: '',  // 유해위험요인명
        ram4mRiskHazardClassCd: null,  // 4M 분류 코드
        ramOccurrClassCd: null,  // 발생구분
        damageTargetName: '',  // 피해대상(key-in)
        damageTypeName: '',  // 재해형태(key-in)
        improveFlag: 'X',  // 개선여부
        existingRiskManagementActivities: '',  // 현재안전조치
        addingRiskManagementActivities: '',  // 추가 리스크 관리활동
        beforeRamMatrixRiskId: '',  // 전 위험도 no
        afterRamMatrixRiskId: '',  // 후 위험도 no
        assessDate: '',  // 평가일
        assessUserId: '',  // 평가자 ID
        assessUserName: '',  // 평가자명
        vendorFlag: null, // 업체 작성 여부
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        imprs: [], // 개선목록
      },
      riskPopComponent: () => import(`${'@/pages/ram/base/matrixPop.vue'}`),
      updateBtnData: {
        title: '평가', // 평가
        flag: false,
        research: '',
      },
      improveFlagItems: [],
      attachHazardInfo: {
        isSubmit: '',
        taskClassCd: 'RISK_HAZARD',
        taskKey: '',
      },
      attachBeforeInfo: {
        isSubmit: '',
        task: [],
        taskClassCd: '4M_SCENARIO_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        task: [],
        taskClassCd: '4M_SCENARIO_AFTER',
        taskKey: '',
      },
      requestImprRow: null,
      activeWinProps: {
        processCd: '',
        ram4mAssessScenarioId: '',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      scenarioViewGroup: 'L',
      rowIndex: 0,
      editable: true,
      isSave: false,
      isSaveUnit: false,
      isComplete: false,
      listUrl: '',
      detailUrl: '',
      listImprUrl: '',
      saveUrl: '',
      saveUnitUrl: '',
      deleteUrl: '',
      completeUrl: '',
      assessPlan: {
        assessmentName: '',  // 평가명
        ramTechniqueName: '', // 평가기법명
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  mounted() {
    this.init();
    this.initializeDataChange();
  },
  beforeDestroy() {
    this.$refs.winPopup.closeWinPop()
    window.removeEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  computed: {
    isCompared() {
      return this.process.reCheckedCnt > 0;
    },
    disabled() {
      return (!(this.param.ramStepCd === 'RRS0000005' || this.param.ramStepCd == 'RRS0000010') || this.process.ramProcessAssessStepCd === 'RPA0000005')
        && !this.updateBtnData.flag
    },
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 180) + 'px' : '500px'
    },
    btnEditable() {
      return this.editable && this.param.ramStepCd === 'RRS0000010' && this.process.ramProcessAssessStepCd === 'RPA0000005'
    },
    flagCondition() {
      return this.param.ramStepCd === 'RRS0000010'
    },
    gridColumns() {
      let cols = [];
      if (this.scenarioViewGroup === 'L') {
        cols = [
          {
            fix: true,
            name: 'jobName',
            field: 'jobName',
            label: '작업',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'ram4mRiskHazardClassName',
            field: 'ram4mRiskHazardClassName',
            label: '평가구분(4M)',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardPicture',
            field: 'riskHazardPicture',
            label: '사진',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          {
            fix: true,
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            label: '관련법규',
            align: 'left',
            style: 'width:120px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'damageTargetName',
            field: 'damageTargetName',
            label: '피해대상',
            align: 'left',
            style: 'width:120px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'ramOccurrClassCd',
            field: 'ramOccurrClassCd',
            label: '발생형태',
            align: 'center',
            type: 'select',
            style: 'width:100px',
            sortable: false,
            codeGroupCd: 'RAM_OCCURR_CLASS_CD'
          },
          {
            name: 'damageTypeName',
            field: 'damageTypeName',
            label: '재해형태',
            align: 'left',
            type: 'text',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            label: '현재안전조치',
            align: 'left',
            type: 'textarea',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                label: '강도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                label: '위험도',
                align: 'center',
                style: 'width:60px',
                sortable: false,
                type: 'proxy',
                component: this.riskPopComponent
              },
            ]
          },
          
          {
            name: 'picture',
            field: 'picture',
            label: '개선 전/후 사진',
            align: 'center',
            type: 'custom',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'addingRiskManagementActivities',
            field: 'addingRiskManagementActivities',
            label: '추가 리스크관리 계획',
            align: 'left',
            type: 'custom',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'improveFlag',
            field: 'improveFlag',
            label: '개선진행여부',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'select',
            comboItems: this.improveFlagItems
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:150px',
                type: 'custom',
                sortable: false
              },
            ]
          },
          {
            name: 'after',
            field: 'after',
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                label: '강도',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                label: '위험도',
                align: 'center',
                style: 'width:60px',
                sortable: false,
                required: true,
                type: 'proxy',
                component: this.riskPopComponent
              },
            ]
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            label: '평가일',
            align: 'center',
            type: 'date',
            style: 'width:120px',
            sortable: false,
            // required: true,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            label: '평가자',
            align: 'center',
            type: !this.param.vendorFlag ? 'user' : 'text',
            userId: 'assessUserId',
            style: 'width:100px',
            sortable: false,
            // required: true,
          },
        ]
        if (!this.param.vendorFlag) {
          cols.splice(cols.length-2, 0, {
            name: 'riskbookFlag',
            field: 'riskbookFlag',
            label: '위험<br/>등록부',
            align: 'center',
            style: 'width:50px',
            sortable: true,
            type: 'check',
            'true': 'Y',
            'false': 'N',
          })
        }
      } else {
        cols = [
          {
            name: 'item',
            field: 'item',
            label: '평가내용',
            align: 'left',
            sortable: false,
            type: 'custom'
          },
        ]
      }
      return cols;
    },
    selectScenario() {
      return Boolean(this.scenario.ram4mAssessScenarioId)
    },
    scenarioTarget() {
      return this.selectScenario ? `${this.scenario.jobName} / ${this.scenario.ram4mRiskHazardClassName} / ${this.scenario.riskHazardName}` : '';
    },
    afterRiskEditable() {
      return this.scenario.improveFlag !== 'N'
    },
    imprComponent() {
      let components = null;
      if (!this.param.vendorFlag) {
        components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      } else {
        components = () => import(`${'@/pages/ram/4m/4mTableImpr.vue'}`);
      }
      return components;
    },
    requestContentsCols() {
      return ['jobName', 'ram4mRiskHazardClassName', 'riskHazardName'];
    }
  },
  methods: {
    init() {
      this.improveFlagItems = [
        { code: 'X', codeName: this.$language('상관없음'), },
        { code: 'Y', codeName: this.$language('개선진행'), },
        { code: 'N', codeName: this.$language('유지'), },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.fm.scenario.list.url
      this.detailUrl = selectConfig.ram.fm.scenario.get.url
      this.ramPlanUrl = selectConfig.ram.assessPlan.get.url;
      this.listImprUrl = selectConfig.ram.fm.riskReduce.impr.url
      this.saveUrl = transactionConfig.ram.fm.scenario.save.url
      this.saveUnitUrl = transactionConfig.ram.fm.scenario.saveUnit.url
      this.deleteUrl = transactionConfig.ram.fm.scenario.delete.url
      this.completeUrl = transactionConfig.ram.assessProcess.complete.url;
      // code setting
      // list setting
      this.$http.url = this.$format(this.ramPlanUrl, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.assessPlan, _result.data)
      },);
    },
    initializeDataChange() {
      if(this.process.fmScenarios && this.process.fmScenarios.length>0) {
        this.$_.forEach(this.process.fmScenarios, scenario => {
          const props = { row: scenario };
          this.datachange(props, null); // col을 null로 전달
        });
      }
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd,
      }
      this.$http.request((_result) => {
        this.process.fmScenarios = _result.data;
      },);
    },
    getDetail(row) {
      this.scenario = row

      this.$http.url = this.$format(this.detailUrl, row.ram4mAssessScenarioId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.scenario, _result.data);
        this.$set(this.attachHazardInfo, 'taskKey', this.scenario.ramRiskHazardId)
        this.$set(this.attachBeforeInfo, 'taskKey', this.scenario.ramKrasAssessScenarioId)
        // this.$set(this.attachAfterInfo, 'taskKey', this.scenario.ramKrasAssessScenarioId)
        this.$set(this.attachAfterInfo, 'task', this.$_.map(this.scenario.imprs, impr => {
          return {
            taskClassCd: 'IBM_AFTER',
            taskKey: impr.sopImprovementId,
          }
        }))
      },);
    },
    getImprs() {
      this.$http.url = this.listImprUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ram4mAssessScenarioId: this.scenario.ram4mAssessScenarioId,
        vendorFlag: this.scenario.vendorFlag,
      }
      this.$http.request((_result) => {
        this.$set(this.scenario, 'imprs', _result.data)
      },);
    },
    research() {
      this.$emit('research', 'scenario')
    },
    /* eslint-disable no-unused-vars */
    imprChange(data) {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.research();
    },
    openImprRequest() {
      let requestContents = this.scenario.jobName + ' / '
        + this.scenario.ram4mRiskHazardClassName + ' / '
        + this.scenario.riskHazardName;
      if (!this.param.vendorFlag) {
        this.popupOptions.title = '개선요청';
        this.popupOptions.param = {
          requestContents: requestContents,
          relationTableKey: this.scenario.ram4mAssessScenarioId,
          ibmTaskTypeCd: 'ITT0000023',
          ibmTaskUnderTypeCd: 'ITTU000030',
        };
        // this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          requestContents: requestContents,
          ram4mAssessScenarioId: this.scenario.ram4mAssessScenarioId,
        };
        this.popupOptions.target = () => import(`${'@/pages/ram/4m/risk/4mImprRiskReduce.vue'}`);
      }
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCardImprPopup;
    },
    linkClick(data, row, flag) {
      this.requestImprRow = row
      this.popupOptions.title = '개선';
      if (!this.param.vendorFlag) {
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.param = {
          ram4mAssessImprId: data.ram4mAssessImprId,
        };
        this.popupOptions.target = () => import(`${'@/pages/ram/4m/risk/4mImprRiskReduceDetail.vue'}`);
      }
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCardImprPopup;
    },
    closeCardImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getImprs();
    },
    /* eslint-disable no-unused-vars */
    callbackProxy(data, props, col) {
      if (col.name === 'beforeRisk') {
        this.$set(this.process.fmScenarios[props.rowIndex], 'beforeFrequency', data.f)
        this.$set(this.process.fmScenarios[props.rowIndex], 'beforeStrength', data.s)
        this.$set(this.process.fmScenarios[props.rowIndex], 'beforeRisk', data.r)
        this.$set(this.process.fmScenarios[props.rowIndex], 'beforeRamMatrixRiskId', data.ramMatrixRiskId)

        if (this.process.fmScenarios[props.rowIndex].improveFlag === 'N') {
          this.$set(this.process.fmScenarios[props.rowIndex], 'afterFrequency', data.f)
          this.$set(this.process.fmScenarios[props.rowIndex], 'afterStrength', data.s)
          this.$set(this.process.fmScenarios[props.rowIndex], 'afterRisk', data.r)
          this.$set(this.process.fmScenarios[props.rowIndex], 'afterRamMatrixRiskId', data.ramMatrixRiskId)
        }
      } else {
        this.$set(this.process.fmScenarios[props.rowIndex], 'afterFrequency', data.f)
        this.$set(this.process.fmScenarios[props.rowIndex], 'afterStrength', data.s)
        this.$set(this.process.fmScenarios[props.rowIndex], 'afterRisk', data.r)
        this.$set(this.process.fmScenarios[props.rowIndex], 'afterRamMatrixRiskId', data.ramMatrixRiskId)
      }
      if (this.process.fmScenarios[props.rowIndex].editFlag !== 'C') {
        this.$set(this.process.fmScenarios[props.rowIndex], 'editFlag', 'U')
        this.$set(this.process.fmScenarios[props.rowIndex], 'chgUserId', this.$store.getters.user.userId)
      }
    },
    callbackCardProxy(data, color, refName) {
      if (refName === 'beforeRiskProxy') {
        // 개선 전
        this.$set(this.scenario, 'beforeFrequency', data.f)
        this.$set(this.scenario, 'beforeStrength', data.s)
        this.$set(this.scenario, 'beforeRisk', data.r)
        this.$set(this.scenario, 'beforeRamMatrixRiskId', data.ramMatrixRiskId)

        if (this.scenario.improveFlag === 'N') {
          this.$set(this.scenario, 'afterFrequency', data.f)
          this.$set(this.scenario, 'afterStrength', data.s)
          this.$set(this.scenario, 'afterRisk', data.r)
          this.$set(this.scenario, 'afterRamMatrixRiskId', data.ramMatrixRiskId)
        }
      } else {
        // 개선 후
        this.$set(this.scenario, 'afterFrequency', data.f)
        this.$set(this.scenario, 'afterStrength', data.s)
        this.$set(this.scenario, 'afterRisk', data.r)
        this.$set(this.scenario, 'afterRamMatrixRiskId', data.ramMatrixRiskId)
      }
      this.$refs[refName].hide();
      this.$forceUpdate();
    },
    tableDataChange(props, col) {
      /**
       * 협력업체 포탈에서는 평가자는 key-in
       * 따라서 평가자를 수정할 시에 id를 제거하도록 처리
       */
      if (col.name === 'assessUserName' && this.param.vendorFlag) {
        this.$set(props.row, 'assessUserId', '')
      }
      if (col.name === 'improveFlag') {
        // 개선을 진행 할 건지 여부 체크
        if (props.row[col.name] === 'N') {
          this.$set(props.row, 'addingRiskManagementActivities', '현상 유지')
          this.$set(props.row, 'afterFrequency', props.row.beforeFrequency)
          this.$set(props.row, 'afterStrength', props.row.beforeStrength)
          this.$set(props.row, 'afterRisk', props.row.beforeRisk)
          this.$set(props.row, 'afterRamMatrixRiskId', props.row.beforeRamMatrixRiskId)
        }
      }
    },
    listView() {
      this.scenarioViewGroup = 'L'
    },
    cardView() {
      this.scenarioViewGroup = 'C'
    },
    improveFlagChange() {
      if (this.scenario.improveFlag === 'N') {
        // 유지하는 경우 개선 전 위험도를 복사 및 계획에는 현상유지 setting
        this.$set(this.scenario, 'addingRiskManagementActivities', '현상 유지')
        this.$set(this.scenario, 'afterFrequency', this.scenario.beforeFrequency)
        this.$set(this.scenario, 'afterStrength', this.scenario.beforeStrength)
        this.$set(this.scenario, 'afterRisk', this.scenario.beforeRisk)
        this.$set(this.scenario, 'afterRamMatrixRiskId', this.scenario.beforeRamMatrixRiskId)
      }
    },
    saveScenario() {
      if (this.$comm.validTable(this.gridColumns, this.process.fmScenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveScenarioCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();
    },
    saveScenarioUnit() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.scenario.chgUserId = this.$store.getters.user.userId

              this.isSaveUnit = !this.isSaveUnit
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveScenarioUnitCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();
    },
    removeScenario() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.process.fmScenarios.splice(this.$_.findIndex(this.process.fmScenarios, item), 1)
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();

              this.planUpdateBtnData.planResearch = uid();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeProcess() {
      // 도면 별 노드에 시나리오가 하나 이상 등록되었는지?
      // 화면에서 추가는 하고 저장을 하지 않았을 시에 해당 데이터도 일괄 저장 처리
      // 저장 처리 후 callback에서 process 목록 재조회
      let isProgress = true;
      if (!this.process.fmScenarios || this.process.fmScenarios.length === 0) {
        isProgress = false
        window.getApp.$emit('ALERT', {
          title: '안내',
          // 공정 [' + this.process.processName + ']에 등록된 시나리오가 없습니다.
          message: this.getLanguage('등록된 시나리오가 없습니다.', {s1: this.process.processName}),
          type: 'warning', // success / info / warning / error
        });
      }

      if (isProgress && this.$comm.validTable(this.grid.columns, this.process.fmScenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '완료하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.process.chgUserId = this.$store.getters.user.userId
            this.process.ramProcessAssessStepCd = 'RPA0000005';

            this.isComplete = !this.isComplete
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeProcessCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('assessComplete', _result.data)

      this.planUpdateBtnData.planResearch = uid();
    },
    openHazardPicture(props) {
      this.popupOptions.title = '유해위험요인 사진'; // 유해위험요인 사진
      this.popupOptions.param = {
        ramRiskHazardId: props.row.ramRiskHazardId,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = '개선 사진'; // 개선 사진
      this.popupOptions.param = {
        ram4mAssessScenarioId: props.row.ram4mAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        ram4mAssessImprIds: props.row.ram4mAssessImprIds,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/4m/action/4mScenarioImprPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    compareScenario() {
      let uri = `/ram/compare/compareScenario?ramTechniqueCd=${this.param.ramTechniqueCd}&ramRiskAssessmentPlanId=${this.param.ramRiskAssessmentPlanId}&processCd=${this.process.processCd}`;
      this.$refs.winPopup.openWinPop(uri, 1560, 700);
    },
    evtCloseWinPopup() {
      Object.assign(this.$data.activeWinProps, this.$options.data().activeWinProps);
    },
    onRecvWinPop(recvObj) {
      if(recvObj) {
        this.$set(this.activeWinProps, 'processCd', recvObj.processCd)
        this.$set(this.activeWinProps, 'ram4mAssessScenarioId', recvObj.ram4mAssessScenarioId)
      }
    },
    setTrClass(props) {
      return props.row.processCd === this.activeWinProps.processCd 
        && props.row.compareId === this.activeWinProps.ram4mAssessScenarioId ? 'bg-purple-2' : '';
    },
    /* 재조회 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 입력만 하고 돌아가는 경우를 대비하기 위함 */
    back() {
      this.getList();
    },
    datachange(props, row) {
      const idList = props.row['sopImprovementIds'] ? props.row['sopImprovementIds'].split(',') : [];
      if(!props.row['addingRiskManagementActivities'] && idList && idList.length>0){
        props.row['addingRiskManagementActivities'] = ''
        
        this.$_.forEach(idList, (item, idx) => {
          this.$http.url = this.$format(selectConfig.sop.ibm.improve.get.url, item);
          this.$http.type = 'GET';
          this.$http.request()(_result => {
            props.row['addingRiskManagementActivities'] += _result.data.actionContents ? (idx > 0 ? '\n' : '') + _result.data.actionContents : '';
          });
        });
      }
    }
  }
};
</script>
<style lang="sass">
.scenario-chip i 
  margin-right: -0.2em !important

.scenario-view-group 
  button
    margin: 0 !important

.scenario-card-title
  margin-left: -5px
  font-size: 0.8rem

.scenario-card-side
  text-align: center
  align-self: center
  // flex-basis: 50%

.card-risk-item
  padding-top: 0px
  padding-bottom: 0px
  min-height: auto
.card-risk-info
  width: auto
  min-width: 0
  max-width: 100%
  flex: 10000 1 0%
  display: flex
  border-radius: 10px
  font-size: 1rem
  height: 30px
.before-risk
  background: #3A98B9
  margin-right: 5px
.after-risk
  background: #3A98B9
  margin-left: 5px
.risk-number
  font-size: 1.3rem

.detail-risk
  font-size: 0.8rem
  font-weight: 600
.detail-risk-num
  font-size: 0.8rem
  font-weight: 700
  color: #e63946

.card-scenario-list
  padding: 0px 16px !important
  min-height: 28px

div.scenario4m
  margin: 0 !important
  padding: 0 !important
  border-radius: 0 !important
  div.col-12
    padding: 0 !important
    margin: 0 !important
    .q-uploader--bordered
      border: 0 !important
    .custom-upload-picture
      border-radius: 0 !important
      overflow: hidden !important
      .q-uploader__list
        overflow: hidden !important
        .empty_dscription
          display: none !important
        .update-picture-card
          height: 35px !important
          .upload-picture
            height: 35px !important
.scenarioDiv
  margin: 0 !important
  padding: 0 !important
  div.col-6
    margin: 0 !important
    padding: 0 !important
div.col-3
  .q-item
    padding: 2px 6px !important
div.scenario4m2
  .empty_dscription
    display: none
.improveFlag 
  position: relative
  top: -10px
</style>
